import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { User } from '../../../models/user.model';
import { UsersListController } from './users-list.controller';
import { UsersService } from '../../../services/users.service';
import { AuthRepo } from 'src/app/modules/auth/repositories/auth.repo';
import { SERVER_URL } from 'src/app/app.config';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.css']
})
export class UsersListComponent implements OnInit, OnDestroy {

  @ViewChild("filterForIdentificationInput") filterInput: ElementRef;

  usersData: User[] = [];

  usersSelectedRef: Subscription = new Subscription();

  constructor(private controller: UsersListController,
    public usersService: UsersService,
    private authRepo: AuthRepo) { }

  ngOnInit() {
    this.controller.setComponent(this);
    this.controller.subscribeRetrieveUserData()
  }

  ngOnDestroy() {
    this.usersSelectedRef.unsubscribe();
  }

  onDelete(user: User) {
    this.controller.delete(user.id);
  }

  onProfile(user: User) {
    this.controller.profile(user.id);
  }

  onCertificates(user: User) {
    this.controller.certificates(user.id);
  }

  onFilterForIdentification(val: string) {
    this.controller.filterForIdentification(val);
  }

  onExportUsers() {
    window.open(SERVER_URL + 'usersExport?api_token=' + this.authRepo.getUserToken(), '_blank');
  }

  onResetPassword(user: User) {
    this.controller.resetPassword(user);
  }

  onCreateUser() {
    this.controller.createUser();
  }

  onClearFilter() {
    this.filterInput.nativeElement.value = '';
    this.usersService.clearFilters();
  }

}
