import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { UserCertificatesController } from './user-certificates.controller';
import { ActivatedRoute } from '@angular/router';
import { UserCertificate } from '../../models/user-certificate.model';
import { Subscription } from 'rxjs';
import { UsersService } from '../../services/users.service';
import { UserCertificatesService } from '../../services/user-certificates.service';

@Component({
  selector: 'app-user-certificates',
  templateUrl: './user-certificates.component.html',
  styleUrls: ['./user-certificates.component.css']
})

export class UserCertificatesComponent implements OnInit, OnDestroy {

  @ViewChild("filterForName") filterInput: ElementRef;

  userCertificatesData: UserCertificate[] = [];
  userCertificatesRef: Subscription = new Subscription();

  constructor(private controller: UserCertificatesController,
    private route: ActivatedRoute,
    private usersService: UsersService,
    public usersCertificatesService: UserCertificatesService) {
    this.controller.setComponent(this);
  }

  ngOnInit() {
    if (this.usersService.validateAuthUser(this.route.snapshot.params['id'])) {
      this.usersCertificatesService.setUserId(this.route.snapshot.params['id']);
      this.controller.getUserCertificatesData();
      this.controller.subscribeRetrieveUserCertificatesData();
    } else {
      this.controller.goHome();
    }

  }

  ngOnDestroy() {
    this.usersCertificatesService.clearFilters();
    this.userCertificatesRef.unsubscribe();
  }

  onGoToUserProfile() {
    this.controller.goToUserProfile(this.route.snapshot.params['id']);
  }

  download(userCertificate: UserCertificate) {
    this.controller.downloadCertificate(userCertificate.id_indexed_file);
  }

  onClearFilter() {
    this.filterInput.nativeElement.value = '';
    this.usersCertificatesService.clearFilters();
  }

  onFilterForName(val: string) {
    this.controller.filterForName(val);
  }

}
