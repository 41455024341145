import { Injectable } from "@angular/core";
import { UsersListComponent } from "./users-list.component";
import { UsersService } from "../../../services/users.service";
import { User } from "../../../models/user.model";
import { Router } from "@angular/router";
import Swal from 'sweetalert2'
import { AuthResource } from "src/app/modules/auth/resources/auth.resource";

@Injectable({
    providedIn: 'root'
})

export class UsersListController {

    component: UsersListComponent;

    constructor(private usersService: UsersService,
        private router: Router,
        private authResource: AuthResource) { }

    setComponent(component: UsersListComponent) {
        this.component = component;
    }

    subscribeRetrieveUserData() {
        this.component.usersSelectedRef = this.usersService.usersRetrieved$.subscribe((users: User[]) => {
            this.component.usersData = users;
        });
    }

    delete(id: number) {
        Swal({
            title: 'Estas seguro',
            text: "Deseas eliminar este usuario",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si'
        }).then((result) => {
            if (result.value) {
                this.usersService.deleteUser(id);
            }
        })
    }

    resetPassword(user: User) {
        Swal({
            title: 'Estas seguro',
            text: "Deseas resetear la contraseña del usuario " + user.full_name,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si'
        }).then((result) => {
            if (result.value) {
                this.usersService.resetUserPassword(user.id).subscribe(() => {
                    Swal({
                        type: 'success',
                        title: 'Contraseña reseteada',
                        text: 'Clave reseteada a los últimos 4 dígitos de su identificación',
                        showConfirmButton: false,
                        timer: 1500,

                    })
                });
            }
        })
    }

    profile(id: number) {
        this.router.navigateByUrl('/home/profile/' + id);
    }

    certificates(id: number) {
        this.router.navigateByUrl('/home/certificates/' + id);
    }

    filterForIdentification(val: string) {
        this.usersService.filterForIdentification(val);
    }

    createUser() {
        this.router.navigateByUrl('/home/userCreate');
    }

}