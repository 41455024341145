import { User } from "./user.model";

export class UserCertificate {

    public id_indexed_file: number;
    public file_path: string;
    public user_id: number;
    public user: User;

    constructor(obj?: any) {

        if (obj) {
            this.id_indexed_file = obj.id_indexed_file;
            this.file_path = obj.file_path;
            this.user_id = obj.user_id;
            if (obj.user) {
                this.user = obj.user;
            }
        }
    }

}