import { Injectable } from "@angular/core";
import { UserNavComponent } from "./user-nav.component";
import { Router } from "@angular/router";
import { AuthService } from "src/app/modules/auth/services/auth.service";

@Injectable({
    providedIn: 'root'
})

export class UserNavController {

    component: UserNavComponent;

    constructor(private authService: AuthService, private router: Router) { }

    setComponent(component: UserNavComponent) {
        this.component = component;
    }

    getUserAuth() {
        return this.authService.getUser();
    }

    signOut() {
        this.authService.onSignOut();
        this.router.navigateByUrl('/login');
    }

}