import { Injectable } from "@angular/core";
import { UsersCreateComponent } from "./users-create.component";
import { UsersService } from "../../services/users.service";

@Injectable({
    providedIn: 'root'
})

export class UsersCreateController {

    component: UsersCreateComponent;

    constructor(private usersService: UsersService) { }

    setComponent(component: UsersCreateComponent) { 
        this.component = component;
    }

    createUser() {
        this.usersService.createUser(this.component.createUserForm.value);
    }

}