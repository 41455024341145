import { Component, OnInit } from '@angular/core';
import { UsersCreateController } from './users-create.controller';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-users-create',
  templateUrl: './users-create.component.html',
  styleUrls: ['./users-create.component.css']
})
export class UsersCreateComponent implements OnInit {

  createUserForm: FormGroup;

  constructor(private controller: UsersCreateController) {
    this.controller.setComponent(this);
  }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.createUserForm = new FormGroup({
      identification: new FormControl(null, Validators.required),
      full_name: new FormControl(null, Validators.required),
      birthday: new FormControl(null),
      expedition_date: new FormControl(null),
      phone_number: new FormControl(null),
      is_admin: new FormControl(null, Validators.required)
    });
  }

  onRegister() {
    if (this.createUserForm.valid) {
      this.controller.createUser();
    }
  }

}
