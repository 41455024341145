import { Injectable } from "@angular/core";
import { AuthResource } from "../../resources/auth.resource";
import { ResetPasswordComponent } from "./reset-password.component";
import Swal from 'sweetalert2'
import { Router } from "@angular/router";

@Injectable({
    providedIn: 'root'
})

export class ResetPasswordController {

    component: ResetPasswordComponent;

    constructor(private authResource: AuthResource,
        private router: Router) {

    }

    setComponent(component: ResetPasswordComponent) {
        this.component = component;
    }

    resetPassword(data: any) {
        this.authResource.resetPassword(this.prepareData(data)).subscribe(() => {
            Swal({
                title: 'Contraseña Reseteada',
                type: 'success',
                text: 'Contraseña reseteada a los últimos 4 dígitos de su identificación',
                showCancelButton: false,
                confirmButtonColor: '#181818',
                confirmButtonText: 'Loguearme',

            }).then((result) => {
                if (result.value) {
                    this.goLogin();
                }
            })
        }, (err) => {
            if (err.error) {
                let msg = '';
                msg = err.error.data ? this.getErrorDataMessage(err.error.data) : err.error.message ? err.error.message : msg;
                Swal({
                    type: 'error',
                    title: 'Oops...',
                    text: msg
                })

            }
        });
    }

    public getErrorDataMessage(data) {
        let msg = '';
        for (var key in data) {
            return msg = data[key][0];
        }
    }

    public prepareData(values) {
        for (var key in values) {
            if (!values[key])
                delete values[key];
        }

        return values;
    }

    goLogin() {
        this.router.navigateByUrl('/login');
    }

}