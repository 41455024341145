export class PaginateInfo {

    current_page: number;
    from: number;
    last_page: number;
    per_page: number;
    to: number;
    total: number;

    constructor(obj? : any) {
        if(obj){
            this.current_page = obj.current_page;
            this.from = obj.from;
            this.last_page = obj.last_page;
            this.per_page = obj.per_page;
            this.to = obj.to;
            this.total = obj.total;
        }
    }

}