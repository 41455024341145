import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import Swal from 'sweetalert2'
import { UpdateProfileComponent } from "./update-profile.component";
import { UsersService } from "src/app/modules/users/services/users.service";
import { User } from "src/app/modules/users/models/user.model";
import { AuthService } from "src/app/modules/auth/services/auth.service";

@Injectable({
    providedIn: 'root'
})

export class UpdateProfileController {

    component: UpdateProfileComponent;

    constructor(private router: Router,
        private authService: AuthService,
        private usersService: UsersService) { }

    setComponent(component: UpdateProfileComponent) {
        this.component = component;
    }

    getUserData(id: number) {
        this.usersService.getUserData(id);
    }

    subscribeRetrieveUserData() {
        this.component.userSelectedRef = this.usersService.userretrieved$.subscribe((user: User) => {
            this.component.userData = user;
            this.component.createForm();
        });
    }

    update() {

        Swal({
            title: 'Estas seguro',
            text: "¿Deseas actualizar tu usuario?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#181818',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Actualizar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.value) {
                let newUser: User = new User();
                newUser.id = this.component.userData.id;
                newUser.identification = this.component.userData.identification;
                newUser.expedition_date = this.component.updateProfileForm.value.expedition_date;
                newUser.full_name = this.component.updateProfileForm.value.full_name;
                newUser.phone_number = this.component.updateProfileForm.value.phone_number;
                newUser.birthday = this.component.updateProfileForm.value.birthday;
                this.usersService.updateUser(newUser);
                this.router.navigateByUrl('/home/certificates/' + newUser.id);
            }
        })
    }

    signOut() {
        this.authService.onSignOut();
        this.router.navigateByUrl('/login');
    }
}