import { Component, OnInit, ViewContainerRef, OnDestroy } from '@angular/core';
import { LoginController } from './login.controller';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  loginForm: FormGroup;
  toastSubscription: Subscription = new Subscription();

  constructor(private controller: LoginController,
    private vcr: ViewContainerRef, ) {
    this.controller.setComponent(this);
  }

  ngOnInit() {
    this.createForm();
  }

  ngOnDestroy() {
    this.toastSubscription.unsubscribe();
  }

  createForm() {
    this.loginForm = new FormGroup({
      identification: new FormControl(null, Validators.required),
      password: new FormControl(null, [Validators.required, Validators.minLength(4), Validators.maxLength(4), , Validators.pattern(/^-?(0|[0-9]\d*)?$/)])
    });
  }

  onLogin() {
    if (this.loginForm.valid) {
      this.controller.login(this.loginForm.value.identification, this.loginForm.value.password);
    }
  }

  onGoRegister() {
    this.controller.goRegister();
  }

  onGoResetPassword() {
    this.controller.goResetPassword();
  }

}
