import { Component, OnInit } from '@angular/core';
import { RegisterController } from './register.controller';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  registerForm: FormGroup;
  date = new Date();
  maxDate = this.date.toJSON().split('T')[0];
  minDate = '';

  constructor(private controller: RegisterController) {
    this.controller.setComponent(this);
    this.date.setFullYear(this.date.getFullYear() - 15);
    this.minDate = this.date.toJSON().split('T')[0];
  }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.registerForm = new FormGroup({
      identification: new FormControl(null, Validators.required),
      expedition_date: new FormControl(null, Validators.required),
      full_name: new FormControl(null, Validators.required),
      password: new FormControl(null, [Validators.required, Validators.minLength(4), Validators.maxLength(4), , Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
      password_confirmation: new FormControl(null, [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
      birthday: new FormControl(null, Validators.required),
      phone_number: new FormControl(null, Validators.required),
      is_admin: new FormControl(false)
    });
  }

  onGoLogin() {
    this.controller.goLogin();
  }

  onRegister() {
    if (this.registerForm.valid) {
      this.controller.register(this.registerForm.value);
    }
  }

}
