export class AuthUser {

    id: number;
    identification: string;
    full_name: string;
    birthday: string;
    expedition_date: string;
    phone_number: string;
    is_admin: boolean;
    created_at: string;
    updated_at: string;
    deleted_at: string;
    token: string;

    constructor(obj?: any) {
        if (obj) {
            this.id = obj.id;
            this.identification = obj.identification;
            this.full_name = obj.full_name;
            this.birthday = obj.birthday;
            this.expedition_date = obj.expedition_date;
            this.phone_number = obj.phone_number;
            this.is_admin = obj.is_admin;
            this.created_at = obj.created_at;
            this.updated_at = obj.updated_at;
            this.deleted_at = obj.deleted_at;
            this.token = obj.token;
        }
    }

}