import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { UsersUploadController } from './users-upload.controller';
import { UsersService } from '../../services/users.service';
import { AuthRepo } from 'src/app/modules/auth/repositories/auth.repo';

@Component({
  selector: 'app-users-upload',
  templateUrl: './users-upload.component.html',
  styleUrls: ['./users-upload.component.css']
})
export class UsersUploadComponent implements OnInit, AfterViewInit {

  file = null;
  @ViewChild("fileInput") selectedFile: ElementRef;

  constructor(private controller: UsersUploadController,
              private elem: ElementRef,
              private usersService: UsersService,
              private authUserRepo: AuthRepo) { }

  ngOnInit() {
    if (!this.usersService.validateAuthUser(this.authUserRepo.getUser().id)) {
      this.controller.goHome();
    }
  }

  ngAfterViewInit() {
  }

  onFileChanged(event: any) {
    this.file = event.srcElement.value;
  }

  onUploadUsers() {
    if (this.file != null) {
      let file = this.selectedFile.nativeElement.files[0];
      let formData = new FormData();
      formData.append('file', file, file.name);
      this.controller.usersUpload(formData);
    }
  }

}
