import { Component, OnInit } from '@angular/core';
import { NavigationController } from './navigation.controller';
import { AuthUser } from 'src/app/modules/auth/models/authuser.model';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {

  userAuth: AuthUser;
  certificatesPath: string;
  profilePath: string;
  usersPath: string;

  constructor(private controller: NavigationController) { 
    this.controller.setComponent(this);
  }

  ngOnInit() {
    this.userAuth = this.controller.getAuthUser();
    console.log(this.userAuth);
    this.certificatesPath = '/home/certificates/' + this.userAuth.id;
    this.profilePath = '/home/profile/' + this.userAuth.id;
    this.usersPath = '/home/users';
  }

}
