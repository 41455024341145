import { Injectable } from "@angular/core";
import { UsersUploadComponent } from "./users-upload.component";
import { UsersService } from "../../services/users.service";
import { Router } from "@angular/router";

@Injectable({
    providedIn: 'root'
})

export class UsersUploadController {

    component: UsersUploadComponent;

    constructor(private usersService: UsersService,
                private router: Router) { }

    setComponent(component: UsersUploadComponent) {
        this.component = component;
    }

    usersUpload(formData: FormData) {
        this.usersService.usersUpload(formData);
    }

    goHome() {
        this.router.navigateByUrl('/home');
    }

}