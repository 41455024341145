import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthRepo } from 'src/app/modules/auth/repositories/auth.repo';
import { ActivatedRoute } from '@angular/router';
import { User } from 'src/app/modules/users/models/user.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UpdateProfileController } from './update-profile.controller';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.css']
})
export class UpdateProfileComponent implements OnInit, OnDestroy {

  userData: User;
  updateProfileForm: FormGroup;

  userSelectedRef: Subscription = new Subscription();

  constructor(private authRepo: AuthRepo,
    private controller: UpdateProfileController,
    private route: ActivatedRoute) {
    this.controller.setComponent(this);

  }

  ngOnInit() {
    this.controller.getUserData(this.route.snapshot.params['id']);
    this.controller.subscribeRetrieveUserData();
  }

  ngOnDestroy() {
    this.userSelectedRef.unsubscribe();
  }

  createForm() {
    this.updateProfileForm = new FormGroup({
      identification: new FormControl(this.userData.identification, Validators.required),
      expedition_date: new FormControl(this.userData.expedition_date, Validators.required),
      full_name: new FormControl(this.userData.full_name, Validators.required),
      phone_number: new FormControl(this.userData.phone_number, Validators.required),
      birthday: new FormControl(this.userData.birthday, Validators.required)
    });
  }

  onUpdate() {
    this.controller.update();
  }

  onSignOut() {
    this.controller.signOut();
  }

}
