import { Component, OnInit, Input } from '@angular/core';
import { PaginateInfo } from 'src/app/modules/general/models/pagination.model';
import { UserCertificatesService } from '../../../services/user-certificates.service';

@Component({
  selector: 'app-user-certificates-pagination',
  templateUrl: './user-certificates-pagination.component.html',
  styleUrls: ['./user-certificates-pagination.component.css']
})
export class UserCertificatesPaginationComponent implements OnInit {

  @Input() paginateInfo: PaginateInfo;

  constructor(public userCertificatesService: UserCertificatesService) { }

  ngOnInit() {
  }

  increasePage() {
    if (this.paginateInfo.current_page < this.paginateInfo.last_page) {
      this.userCertificatesService.increasePage();
    }
  }

  decreasePage() {
    if (this.paginateInfo.current_page > 1) {
      this.userCertificatesService.decreasePage();
    }
  }

}
