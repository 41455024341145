import { Injectable } from "@angular/core";
import { UserCertificatesComponent } from "./user-certificates.component";
import { Router } from "@angular/router";
import { UserCertificatesService } from "../../services/user-certificates.service";
import { UserCertificate } from "../../models/user-certificate.model";
import { AuthRepo } from "src/app/modules/auth/repositories/auth.repo";
import { SERVER_URL } from "src/app/app.config";


@Injectable({
    providedIn: 'root'
})

export class UserCertificatesController {

    component: UserCertificatesComponent;

    constructor(private router: Router,
        private userCertificatesService: UserCertificatesService,
        private authRepo: AuthRepo) {
    }

    setComponent(component: UserCertificatesComponent) {
        this.component = component;
    }

    goToUserProfile(id: number) {
        this.router.navigateByUrl('/home/profile/' + id);
    }

    goHome() {
        this.router.navigateByUrl('/home');
    }

    getUserCertificatesData() {
        this.userCertificatesService.getUserCertificatesData();
    }

    subscribeRetrieveUserCertificatesData() {
        this.component.userCertificatesRef = this.userCertificatesService.userCertificatesRetrieved$.subscribe((userCertificates: UserCertificate[]) => {
            this.component.userCertificatesData = userCertificates;
        });
    }

    downloadCertificate(id: number) {

        window.open(SERVER_URL + 'downloadCertificate/' + id + '?api_token=' + this.authRepo.getUserToken(), '_blank');
    }

    filterForName(val: string) {
        this.userCertificatesService.filterForName(val);
    }



}