import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './modules/auth/components/login/login.component';
import { RegisterComponent } from './modules/auth/components/register/register.component';
import { ResetPasswordComponent } from './modules/auth/components/reset-password/reset-password.component';
import { HomeComponent } from './modules/general/components/home/home.component';
import { ProfileComponent } from './modules/users/components/profile/profile.component';
import { ChangePasswordComponent } from './modules/users/components/change-password/change-password.component';
import { UserCertificatesComponent } from './modules/users/components/user-certificates/user-certificates.component';
import { UsersComponent } from './modules/users/components/users/users.component';
import { UsersUploadComponent } from './modules/users/components/users-upload/users-upload.component';
import { UsersCreateComponent } from './modules/users/components/users-create/users-create.component';
import { UpdateProfileComponent } from './modules/general/components/update-profile/update-profile.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'resetPassword', component: ResetPasswordComponent },
  {
    path: 'home', component: HomeComponent, children: [
      { path: 'profile/:id', component: ProfileComponent },
      { path: 'changePassword/:id', component: ChangePasswordComponent },
      { path: 'certificates/:id', component: UserCertificatesComponent },
      { path: 'users', component: UsersComponent },
      { path: 'usersUpload', component: UsersUploadComponent },
      { path: 'userCreate', component: UsersCreateComponent }
    ]
  },
  { path: 'updateProfile/:id', component: UpdateProfileComponent },
  { path: '**', redirectTo: '/home', pathMatch: 'full' }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
