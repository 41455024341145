import { Injectable } from "@angular/core";
import { UsersComponent } from "./users.component";
import { Router } from "@angular/router";
import { UsersService } from "../../services/users.service";
import { User } from "../../models/user.model";

@Injectable({
    providedIn: 'root'
})

export class UsersController {

    component: UsersComponent;

    constructor(private router: Router,
                private usersService: UsersService) { }

    setComponent(component: UsersComponent) {
        this.component = component;
    }

    goHome() {
        this.router.navigateByUrl('/home');
    }

    getUsersData() {
        this.usersService.getUsersData();
    }

}