import { Component, OnInit } from '@angular/core';
import { ResetPasswordController } from './reset-password.controller';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  resetPasswordForm: FormGroup;

  constructor(private controller: ResetPasswordController) {
    this.controller.setComponent(this);
  }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.resetPasswordForm = new FormGroup({
      identification: new FormControl(null, Validators.required),
      phone_number: new FormControl('', Validators.required),
      birthday: new FormControl('', Validators.required),
      expedition_date: new FormControl(null, Validators.required)
    }, this.requiredIfNotValidator);
  }

  onResetPassword() {
    if (this.resetPasswordForm.valid) {
      this.controller.resetPassword(this.resetPasswordForm.value);
    }
  }

  requiredIfNotValidator(group: FormGroup) {

    if (group.get('phone_number').valid) {
      group.get('birthday').disable({ onlySelf: true })
    } else {
      group.get('birthday').enable({ onlySelf: true })
    }

    if (group.get('birthday').valid) {
      group.get('phone_number').disable({ onlySelf: true })
    } else {
      group.get('phone_number').enable({ onlySelf: true })
    }

    return null;
  }

  clearValidator(group, field) {
    group.get(field).clearValidators();
    group.get(field).updateValueAndValidity();
  }

  addRequiredValidator(group, field) {
    group.get(field).setValidators(Validators.required);
    group.get(field).updateValueAndValidity();
  }

  onGoLogin() {
    this.controller.goLogin();
  }
}
