import { Injectable } from '@angular/core';
import { LoginComponent } from './login.component';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})

export class LoginController {

    component: LoginComponent;

    constructor(private authService: AuthService,
        private router: Router) { }

    setComponent(component: LoginComponent) {
        this.component = component;
    }

    login(identification, password) {
        this.authService.login(identification, password).subscribe((user: any) => {
            this.authService.saveUser(user);
            if (user.expedition_date) {
                this.router.navigateByUrl('/home/profile/' + user.id);
            } else {
                this.router.navigateByUrl('/updateProfile/' + user.id);
            }

        });
    }

    goRegister() {
        this.router.navigateByUrl('/register');
    }

    goResetPassword() {
        this.router.navigateByUrl('/resetPassword');
    }

}