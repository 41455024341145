import { Injectable } from "@angular/core";
import { map } from 'rxjs/operators';
import { StorageService } from '../../general/services/storage.service';
import { from } from 'rxjs';
import { AuthUser } from "../models/authuser.model";

@Injectable({
    providedIn:'root'
})

export class AuthRepo {

    protected user: AuthUser = null;
    protected token: string;

    constructor(protected storageService: StorageService) { }

    load(){
        return from(this.storageService.read('me_goldrh')).pipe(
            map(this.map)
        );
    }

    getUser(): AuthUser {
        return JSON.parse(this.storageService.read('me_goldrh'));
    }

    updateImage(user_id, photo) {
        let new_user: AuthUser =  new AuthUser();
        new_user = this.getUser();
        
        if (user_id === new_user.id) {
            this.setUser(new_user);
            return new_user;
        } else {
            return this.getUser();
        }
    }

    getUserToken(): string {
        let user: AuthUser = JSON.parse(this.storageService.read('me_goldrh'));
        if (user) {
            return user.token;    
        } else {
            return null;
        }
    }

    setUser(user:any) {
        this.user = new AuthUser(user);
        this.saveUser(this.user);
    }

    deleteUser() {
        this.storageService.delete('me_goldrh');
    }

    private saveUser(user: AuthUser) {
        let str = JSON.stringify(user);
        this.storageService.write('me_goldrh', str);
    }

    updateUser(user:AuthUser) {
        user.token = this.getUserToken();
        let str = JSON.stringify(user);
        this.storageService.write('me_goldrh', str);
    }

    private map(data){
        if(data){
            return new AuthUser(JSON.parse(data));
        }
        return data;
    }

}