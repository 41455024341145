import { Injectable } from "@angular/core";
import { ChangePasswordComponent } from "./change-password.component";
import { UsersService } from "../../services/users.service";

@Injectable({
    providedIn: 'root'
})

export class ChangePasswordController {

    component: ChangePasswordComponent;

    constructor(private usersService: UsersService) { }

    setComponent(component: ChangePasswordComponent) {
        this.component = component;
    }

    changePassword(id: number, data: any) {
        this.usersService.changePassword(id, data);
    }

}