import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { UserCertificate } from "../models/user-certificate.model";
import { UserCertificatesResource } from "../resources/users-certificate.resource";
import { PaginateInfo } from "../../general/models/pagination.model";
import { ActivatedRoute } from "@angular/router";
import Swal from 'sweetalert2'
import { Filter } from "../../general/models/filter.model";
import { AuthRepo } from "../../auth/repositories/auth.repo";

@Injectable({
    providedIn: 'root'
})

export class UserCertificatesService {

    private userId: number;

    private userCertificatesRetrievedSource = new Subject<UserCertificate[]>();
    public userCertificatesRetrieved$ = this.userCertificatesRetrievedSource.asObservable();

    public withFilter = false;

    constructor(private userCertificatesResource: UserCertificatesResource,
                private route: ActivatedRoute,
                private authRepo: AuthRepo) { }

    setUserId(id: number) {
        this.userId = id;
    }

    getUserCertificatesData(page?: number) {
        this.userCertificatesResource.setEntity('users/' + this.userId +'/certificates');
        this.userCertificatesResource.all(page).subscribe((userCertificates: any[]) => {
            this.userCertificatesRetrievedSource.next(userCertificates);
        }, (err) => {
            if (err.error) {
                let msg = '';
                msg = err.error.data ? this.getErrorDataMessage(err.error.data) : err.error.message ? err.error.message : msg;
                Swal({
                    type: 'error',
                    title: 'Oops...',
                    text: msg
                })
            }
        });
    }

    getPaginationInfo(): PaginateInfo {
        return this.userCertificatesResource.getPaginateInfo();
    }

    increasePage() {
        this.getUserCertificatesData(this.getPaginationInfo().current_page + 1);
    }

    decreasePage() {
        this.getUserCertificatesData(this.getPaginationInfo().current_page - 1);
    }

    public getErrorDataMessage(data) {
        let msg = '';
        for (var key in data) {
            return msg = data[key][0];
        }
    }


    filterForName(val: string) {
        let filter = null;
        if (val != '') {
            this.userCertificatesResource.clearFilters();
            filter = new Filter('file_path', 'like', val);
            this.userCertificatesResource.addFilter(filter);
            this.userCertificatesResource.applyQueryFilters();
            this.getUserCertificatesData();
            this.withFilter = true;
        } else {
            filter = null;
            this.userCertificatesResource.clearFilters();
            this.getUserCertificatesData(1);
            this.withFilter = false;
        }
    }

    clearFilters() {
        if (this.authRepo.getUserToken()) {
            this.userCertificatesResource.clearFilters();
            this.getUserCertificatesData(1);
            this.withFilter = false;
        }
    }

}