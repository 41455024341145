import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProfileController } from './profile.controller';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UsersService } from '../../services/users.service';
import { User } from '../../models/user.model';
import { Subscription } from 'rxjs';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit, OnDestroy {

  userData: User;
  profileForm: FormGroup;

  userSelectedRef: Subscription = new Subscription();

  constructor(private controller: ProfileController,
    public usersService: UsersService,
    private route: ActivatedRoute) {
    this.controller.setComponent(this);
  }

  ngOnInit() {

    this.route.paramMap.subscribe((params: ParamMap) => {
      let userId = Number(params.get('id'));
      if (this.usersService.validateAuthUser(userId)) {
        this.controller.getUserData(userId);
        this.controller.subscribeRetrieveUserData();
      } else {
        this.controller.goHome();
      }

    })

  }

  ngOnDestroy() {
    this.userSelectedRef.unsubscribe();
  }

  createForm() {
    this.profileForm = new FormGroup({
      identification: new FormControl(this.userData.identification, Validators.required),
      full_name: new FormControl(this.userData.full_name, Validators.required),
      birthday: new FormControl(this.userData.birthday, Validators.required),
      phone_number: new FormControl(this.userData.phone_number, Validators.required),
      expedition_date: new FormControl(this.userData.expedition_date, Validators.required),
      is_admin: new FormControl(this.userData.is_admin, Validators.required)
    });
  }

  onUpdate() {
    this.controller.update();
  }

  onChangePassword() {
    this.controller.changePassword(this.route.snapshot.params['id']);
  }

  onGoToUsersCertificates() {
    this.controller.goToUsersCertificates(this.route.snapshot.params['id']);
  }

  getAuthUser() {
    return this.controller.getAuthUser();
  }
}
