import { Injectable } from "@angular/core";
import { RegisterComponent } from "./register.component";
import { AuthResource } from "../../resources/auth.resource";
import Swal from 'sweetalert2'
import { Router } from "@angular/router";

@Injectable({
    providedIn: 'root'
})

export class RegisterController {

    component: RegisterComponent;

    constructor(private authResource: AuthResource,
        private router: Router) {

    }

    setComponent(component: RegisterComponent) {
        this.component = component;
    }

    goLogin() {
        this.router.navigateByUrl('/login');
    }

    register(data: any) {
        this.authResource.registerUser(data).subscribe((user: any) => {
            Swal({
                title: 'Usuario registrado correctamente',
                type: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Loguearme'
            }).then((result) => {
                if (result.value) {
                    this.goLogin();
                }
            })
        }, (err) => {
            if (err.error) {
                let msg = '';
                msg = err.error.data ? this.getErrorDataMessage(err.error.data) : err.error.message ? err.error.message : msg;
                Swal({
                    type: 'error',
                    title: 'Oops...',
                    text: msg
                })
            }
        });
    }

    public getErrorDataMessage(data) {
        let msg = '';
        for (var key in data) {
            return msg = data[key][0];
        }
    }

}