import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})

export class StorageService {

    write(key: string, value: any) {
        if (value) {
          value = this.encode(JSON.stringify(value));
        }

        if (key) {
            key = this.encode(JSON.stringify(key));
        }

        localStorage.setItem(key, value);
    }

    read(key: string): string {
        key = JSON.stringify(key);
        let value: string = localStorage.getItem(this.encode(key));
        if (value && value != 'undefined' && value != 'null') {
            return JSON.parse(this.decode(value.replace('"', '').replace('"', '')));
        }
        return null;
    }
      
    delete(key: string) {
        if (key) {
            key = this.encode(JSON.stringify(key));
        }
        localStorage.removeItem(key);
    }

    encode( str ) {
        return btoa(str);
    }

    decode( str ) {
        return atob(str);
    }

}