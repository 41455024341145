import { Injectable } from "@angular/core";
import { ProfileComponent } from "./profile.component";
import { Router } from "@angular/router";
import { User } from "../../models/user.model";
import Swal from 'sweetalert2'
import { AuthRepo } from "src/app/modules/auth/repositories/auth.repo";
import { UsersService } from "../../services/users.service";

@Injectable({
    providedIn: 'root'
})

export class ProfileController {

    component: ProfileComponent;

    constructor(private router: Router,
        private authRepo: AuthRepo,
        private usersService: UsersService) { }

    setComponent(component: ProfileComponent) {
        this.component = component;
    }

    goHome() {
        this.router.navigateByUrl('/home');
    }

    getUserData(id: number) {
        this.usersService.getUserData(id);
    }

    subscribeRetrieveUserData() {
        this.component.userSelectedRef = this.usersService.userretrieved$.subscribe((user: User) => {
            this.component.userData = user;
            this.component.createForm();
        });
    }

    update() {
        Swal({
            title: 'Estas seguro',
            text: "Deseas actualizar tu usuario",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si'
        }).then((result) => {
            if (result.value) {
                let newUser: User = new User();
                newUser.id = this.component.userData.id;
                newUser.full_name = this.component.profileForm.value.full_name;
                newUser.birthday = this.component.profileForm.value.birthday;
                newUser.expedition_date = this.component.profileForm.value.expedition_date;
                newUser.identification = this.component.userData.identification;
                newUser.phone_number = this.component.profileForm.value.phone_number;

                if (this.component.profileForm.value.is_admin != this.component.userData.is_admin) {
                    newUser.is_admin = (this.component.profileForm.value.is_admin == 'true');
                } else {
                    newUser.is_admin = this.component.userData.is_admin;
                }
                this.usersService.updateUser(newUser);
            }
        })
    }

    changePassword(id: number) {
        this.router.navigateByUrl('/home/changePassword/' + id);
    }

    goToUsersCertificates(id: number) {
        this.router.navigateByUrl('home/certificates/' + id);
    }

    getAuthUser() {
        return this.authRepo.getUser();
    }
}