import { HttpClient, HttpErrorResponse, HttpParams } from "@angular/common/http";
import { SERVER_URL } from '../../../app.config';
import { Injectable } from "@angular/core";
import { map, catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { AuthUser } from "../models/authuser.model";


@Injectable({
    providedIn:'root'
})

export class AuthResource {

    constructor(private http: HttpClient) { }

    login(username: string, password: string) {
        let body = {
            identification: username,
            password: password
        };
        return this.http.post(SERVER_URL + 'login', body).pipe(
            map((res:any) => res.data),
            catchError(this.handleError)
        );
    }

    registerUser(data: any) {
        return this.http.post(SERVER_URL + 'register', data).pipe(
            map((res: any) => res.data),
            catchError(this.handleError)
        );
    }

    updateUser(user,token) {
        const options = 
            { params: new HttpParams().set('api_token', token) };
        return this.http.put(SERVER_URL + 'users/' + user.id, user, options).pipe(
            map((res: any) => this.map(res.data, token)),
            catchError(this.handleError)
        );
    }

    me(token) {
        const options = token ? 
        { params: new HttpParams().set('api_token', token)} : {};
        return this.http.get(SERVER_URL + 'me', options).pipe(
            map((res:any) => this.map(res.data, token)),
            catchError(this.handleError)
        )
    }

    resetPassword(data: any) {
        return this.http.post(SERVER_URL + 'resetPassword', data).pipe(
            map((res: any) => res.data),
            catchError(this.handleError)
        );
    }

    private map(data, token=null){
        if(token){
            data.token= token; 
        }
        return new AuthUser(data);
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        }
        console.log(error);
        return throwError(error);
    }

}