import { Component, OnInit, Input } from '@angular/core';
import { UsersService } from '../../../services/users.service';
import { PaginateInfo } from 'src/app/modules/general/models/pagination.model';

@Component({
  selector: 'app-users-pagination',
  templateUrl: './users-pagination.component.html',
  styleUrls: ['./users-pagination.component.css']
})
export class UsersPaginationComponent implements OnInit {

  @Input() paginateInfo: PaginateInfo;

  constructor(public usersService: UsersService) { }

  ngOnInit() {
  }

  increasePage() {
    if (this.paginateInfo.current_page < this.paginateInfo.last_page) {
      this.usersService.increasePage();
    }
  }

  decreasePage() {
    if (this.paginateInfo.current_page > 1) {
      this.usersService.decreasePage();
    }
  }

}
