import { Injectable } from "@angular/core";
import { UsersResource } from "../resources/users.resource";
import { User } from "../models/user.model";
import { Subject } from "rxjs";
import Swal from 'sweetalert2'
import { AuthRepo } from "../../auth/repositories/auth.repo";
import { Router } from "@angular/router";
import { Filter } from "../../general/models/filter.model";
import { PaginateInfo } from "../../general/models/pagination.model";

@Injectable({
    providedIn: 'root'
})

export class UsersService {

    private userRetrievedSource = new Subject<User>();
    public userretrieved$ = this.userRetrievedSource.asObservable();

    private usersRetrievedSource = new Subject<User[]>();
    public usersRetrieved$ = this.usersRetrievedSource.asObservable();

    public withFilter = false;

    constructor(private userResource: UsersResource,
        private authRepo: AuthRepo,
        private router: Router) {
    }

    getUserData(id: number) {
        this.userResource.setEntity('users');
        this.userResource.get(id).subscribe((user: any) => {
            this.userRetrievedSource.next(user);
        }, (err) => {
            if (err.error) {
                let msg = '';
                msg = err.error.data ? this.getErrorDataMessage(err.error.data) : err.error.message ? err.error.message : msg;
                Swal({
                    type: 'error',
                    title: 'Oops...',
                    text: msg
                })
            }
        });
    }

    getUsersData(page?: number) {
        this.userResource.setEntity('users');
        this.userResource.all(page).subscribe((users: User[]) => {
            this.usersRetrievedSource.next(users);
        }, (err) => {
            if (err.error) {
                let msg = '';
                msg = err.error.data ? this.getErrorDataMessage(err.error.data) : err.error.message ? err.error.message : msg;
                Swal({
                    type: 'error',
                    title: 'Oops...',
                    text: msg
                })
            }
        });
    }

    updateUser(user: any) {
        this.userResource.setEntity('users');
        this.userResource.update(user.id, user).subscribe((res: any) => {
            if (this.authRepo.getUser().id === user.id) {
                this.authRepo.updateUser(res.data);
            }
            Swal({
                position: 'top-end',
                type: 'success',
                title: 'Exito',
                text: 'El usuario ha sido actualizado',
                padding: 0,
                heightAuto: false,
                width: 300,
                showConfirmButton: false,
                timer: 1500
            })
        }, (err) => {
            if (err.error) {
                let msg = '';
                msg = err.error.data ? this.getErrorDataMessage(err.error.data) : err.error.message ? err.error.message : msg;
                Swal({
                    type: 'error',
                    title: 'Oops...',
                    text: msg
                })
            }
        });
    }

    deleteUser(id: number) {
        this.userResource.setEntity('users');
        this.userResource.delete(id).subscribe(() => {
            Swal({
                position: 'top-end',
                type: 'success',
                title: 'El usuario ha sido eliminado',
                padding: 0,
                heightAuto: false,
                width: 300,
                showConfirmButton: false,
                timer: 1500
            })
            this.getUsersData();
        }, (err) => {
            if (err.error) {
                let msg = '';
                msg = err.error.data ? this.getErrorDataMessage(err.error.data) : err.error.message ? err.error.message : msg;
                Swal({
                    type: 'error',
                    title: 'Oops...',
                    text: msg
                })
            }
        });
    }

    resetUserPassword(id: number) {
        this.userResource.setEntity('users/' + id + '/resetPassword');
        return this.userResource.resetPassword();
    }

    changePassword(id: number, data: any) {
        this.userResource.setEntity('users/' + id + '/changePassword');
        this.userResource.changePassword(data).subscribe(() => {
            Swal({
                title: 'Contraseña cambiada correctamente',
                type: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ir a perfil'
            }).then((result) => {
                if (result.value) {
                    this.goToProfile(id);
                }
            })
        }, (err) => {
            if (err.error) {
                let msg = '';
                msg = err.error.data ? this.getErrorDataMessage(err.error.data) : err.error.message ? err.error.message : msg;
                Swal({
                    type: 'error',
                    title: 'Oops...',
                    text: msg
                })
            }
        });
    }

    createUser(data: any) {
        this.userResource.setEntity('users');
        this.userResource.store(data).subscribe((user: User) => {
            Swal({
                title: 'Usuario creado correctamente',
                type: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ir a usuarios',
                padding: 0,
                heightAuto: false,
                width: 300
            }).then((result) => {
                if (result.value) {
                    this.goToUsers();
                }
            })
        }, (err) => {
            if (err.error) {
                let msg = '';
                msg = err.error.data ? this.getErrorDataMessage(err.error.data) : err.error.message ? err.error.message : msg;
                Swal({
                    type: 'error',
                    title: 'Oops...',
                    text: msg
                })
            }
        });
    }

    goToProfile(id: number) {
        this.router.navigateByUrl('/home/profile/' + id);
    }

    goToUsers() {
        this.router.navigateByUrl('/home/users');
    }

    validateAuthUser(id: number) {
        let user: User = this.authRepo.getUser();
        if (user.is_admin) {
            return true;
        } else {
            if (user.id == id) {
                return true;
            }
        }
        return false;
    }

    validateIsAdmin() {
        let user: User = this.authRepo.getUser();
        if (user.is_admin) {
            return true
        }
        return false;
    }

    filterForIdentification(val: string) {
        let filter = null;
        if (val != '') {
            this.userResource.clearFilters();
            filter = new Filter('identification', 'like', val);
            this.userResource.addFilter(filter);
            this.userResource.applyQueryFilters();
            this.getUsersData();
            this.withFilter = true;
        } else {
            filter = null;
            this.userResource.clearFilters();
            this.getUsersData(1);
            this.withFilter = false;
        }
    }

    clearFilters() {
        if (this.authRepo.getUserToken()) {
            this.userResource.clearFilters();
            this.getUsersData(1);
            this.withFilter = false;
        }
    }

    getPaginationInfo(): PaginateInfo {
        return this.userResource.getPaginateInfo();
    }

    increasePage() {
        this.getUsersData(this.getPaginationInfo().current_page + 1);
    }

    decreasePage() {
        this.getUsersData(this.getPaginationInfo().current_page - 1);
    }

    usersUpload(formData: FormData) {
        this.userResource.setEntity('usersImport');
        this.userResource.uploadFile(formData).subscribe((res) => {
            Swal({
                position: 'top-end',
                type: 'success',
                title: 'Los usuarios han sido cargados satisfactoriamente',
                showConfirmButton: false,
                timer: 1500
            })
        }, (err) => {
            Swal({
                type: 'error',
                title: 'Oops...',
                text: err.error.data.error[0]
            })
        });
    }

    public getErrorDataMessage(data) {
        let msg = '';
        for (var key in data) {
            return msg = data[key][0];
        }
    }



}