import { Component, OnInit } from '@angular/core';
import { ChangePasswordController } from './change-password.controller';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  changePasswordForm: FormGroup;

  constructor(private controller: ChangePasswordController,
    private route: ActivatedRoute) {
    this.controller.setComponent(this);
  }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.changePasswordForm = new FormGroup({
      password: new FormControl(null, [Validators.required, Validators.minLength(4), Validators.maxLength(4), , Validators.pattern(/^-?(0|[0-9]\d*)?$/)]),
      new_password: new FormControl(null, [Validators.required, Validators.minLength(4), Validators.maxLength(4), , Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
      new_password_confirmation: new FormControl(null, [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])
    });
  }

  onChangePassword() {
    if (this.changePasswordForm.valid) {
      this.controller.changePassword(this.route.snapshot.params['id'], this.changePasswordForm.value);
    }
  }

}
