import { Injectable } from "@angular/core";
import { NavigationComponent } from "./navigation.component";
import { AuthRepo } from "src/app/modules/auth/repositories/auth.repo";

@Injectable({
    providedIn: 'root'
})

export class NavigationController {

    component: NavigationComponent;

    constructor(private userAuthRepo: AuthRepo) { }

    setComponent(component: NavigationComponent) {
        this.component = component;
    }

    getAuthUser() {
        return this.userAuthRepo.getUser();
    }

}