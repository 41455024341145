import { Injectable } from "@angular/core";
import { BaseHttpService } from "../../general/services/base-http.service";
import { UserCertificate } from "../models/user-certificate.model";

@Injectable({
    providedIn: 'root'
})

export class UserCertificatesResource extends BaseHttpService {

    protected map(data) {
        return new UserCertificate(data);
    }

}