import { Injectable } from "@angular/core";
import { AuthRepo } from '../repositories/auth.repo';
import { AuthResource } from '../resources/auth.resource';
import { Observable } from "rxjs";
import { AuthUser } from "../models/authuser.model";
import Swal from 'sweetalert2'
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})

export class AuthService {

    constructor(private authResource: AuthResource,
        private authRepo: AuthRepo,
        private router: Router) { }

    login(user, password) {
        return new Observable(observer => {
            this.authResource.login(user, password).subscribe(data => {
                this.authResource.me(data.token).subscribe(user => {
                    user.token = data.token;
                    observer.next(user);
                    observer.complete();
                });
            }, (err) => {
                switch (err.status) {
                    case 400:
                        Swal({
                            title: 'Usuario no registrado',
                            type: 'error',
                            showCancelButton: false,
                            confirmButtonColor: '#181818',
                            confirmButtonText: 'Regístrate'
                        }).then((result) => {
                            if (result.value) {
                                this.goRegister();
                            }
                        })
                        break;
                    case 401:
                        if (err.error) {
                            let msg = '';
                            msg = err.error.message;
                            Swal({
                                type: 'error',
                                title: 'Oops...',
                                confirmButtonColor: '#181818',
                                confirmButtonText: 'Tu contraseña es inválida',
                                text: msg
                            })
                        }
                        break;
                    default:
                        console.log(err);
                        if (err.error) {
                            let msg = '';
                            msg = err.error.message;
                            Swal({
                                type: 'error',
                                title: 'Oops...',
                                confirmButtonColor: '#181818',
                                confirmButtonText: 'Ok',
                                text: msg
                            })
                        }
                        break;
                }
            });
        });
    }

    goRegister() {
        this.router.navigateByUrl('/register');
    }

    public getErrorDataMessage(data) {
        let msg = '';
        for (var key in data) {
            return msg = data[key][0];
        }
    }

    loadUser() {
        return this.authRepo.load();
    }

    getUser() {
        return this.authRepo.getUser();
    }

    saveUser(user: AuthUser) {
        this.authRepo.setUser(user);
    }

    onSignOut() {
        this.authRepo.deleteUser();
    }

}