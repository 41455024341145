import { Component, OnInit, OnDestroy } from '@angular/core';
import { UsersController } from './users.controller';
import { UsersService } from '../../services/users.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { User } from '../../models/user.model';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit, OnDestroy {

  constructor(private controller: UsersController,
              public usersService: UsersService,
              private route: ActivatedRoute) {
    this.controller.setComponent(this);
  }

  ngOnInit() {
    if (this.usersService.validateAuthUser(this.route.snapshot.params['id'])) {
      this.controller.getUsersData();
    } else {
      this.controller.goHome();
    }
  }

  ngOnDestroy() {
    this.usersService.clearFilters();
  }

}
