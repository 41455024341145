import { Injectable } from "@angular/core";
import { BaseHttpService } from "../../general/services/base-http.service";
import { User } from "../models/user.model";

@Injectable({
    providedIn: 'root'
})

export class UsersResource extends BaseHttpService {

    protected map(data) {
        return new User(data);
    }

}