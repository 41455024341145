import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProfileComponent } from './components/profile/profile.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { UserCertificatesComponent } from './components/user-certificates/user-certificates.component';
import { UserNavComponent } from './components/user-nav/user-nav.component';
import { UsersComponent } from './components/users/users.component';
import { UsersListComponent } from './components/users/users-list/users-list.component';
import { UsersPaginationComponent } from './components/users/users-pagination/users-pagination.component';
import { UserCertificatesPaginationComponent } from './components/user-certificates/user-certificates-pagination/user-certificates-pagination.component';
import { UsersUploadComponent } from './components/users-upload/users-upload.component';
import { RouterModule } from '@angular/router';
import { UsersCreateComponent } from './components/users-create/users-create.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule
  ],
  declarations: [
    
  ProfileComponent,
    
  ChangePasswordComponent,
    
  UserCertificatesComponent,
    
  UserNavComponent,
    
  UsersComponent,
    
  UsersListComponent,
    
  UsersPaginationComponent,
    
  UserCertificatesPaginationComponent,
    
  UsersUploadComponent,
    
  UsersCreateComponent]
})
export class UsersModule { }
