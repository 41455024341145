import { Component, OnInit } from '@angular/core';
import { UserNavController } from './user-nav.controller';
import { AuthUser } from 'src/app/modules/auth/models/authuser.model';

@Component({
  selector: 'app-user-nav',
  templateUrl: './user-nav.component.html',
  styleUrls: ['./user-nav.component.css']
})
export class UserNavComponent implements OnInit {

  userAuth: AuthUser;

  constructor(private controller: UserNavController) { 
    this.controller.setComponent(this);
  }

  ngOnInit() {
    this.userAuth = this.controller.getUserAuth();
  }

  onSignOut() {
    this.controller.signOut();
  }

}
