import { HttpClient } from "@angular/common/http";
import { SERVER_URL } from "../../../app.config";
import { Filter } from "../models/filter.model";
import { AuthRepo } from "../../auth/repositories/auth.repo";
import 'rxjs';
import { map, catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { PaginateInfo } from "../models/pagination.model";
import { HttpHeaders } from '@angular/common/http';

@Injectable()

export abstract class BaseHttpService {

    protected paginateInfo: PaginateInfo = new PaginateInfo();
    protected entity: string = '';
    protected filters: Filter[] = [];
    protected queryFilters = '';
    constructor(private http: HttpClient, protected auth: AuthRepo) { }

    all(page?: number) {
        let pageFilter = page ? "&page= " + page : '';
        return this.http.get(SERVER_URL + this.entity + "?api_token=" + this.auth.getUserToken() + pageFilter + this.queryFilters).pipe(
            map((res: any) => this.mapArray(res.data)) 
        );
    }

    get(id) {
        return this.http.get(SERVER_URL + this.entity + "/" + id + "?api_token=" + this.auth.getUserToken()).pipe(
            map((res: any) => this.map(res.data))
        );
    }

    getOnlyOne() {
        return this.http.get(SERVER_URL + this.entity + "?api_token=" + this.auth.getUserToken()).pipe(
            map((res: any) => this.map(res.data))
        );
    }

    store(data) {
        return this.http.post(SERVER_URL + this.entity + '?api_token=' + this.auth.getUserToken(), data);
    }

    update(id, data) {
        return this.http.put(SERVER_URL + this.entity + '/' + id + '?api_token=' + this.auth.getUserToken(), data);
    }

    resetPassword(data?: any) {
        return this.http.put(SERVER_URL + this.entity + '?api_token=' + this.auth.getUserToken(), data);
    }

    changePassword(data: any) {
        return this.http.put(SERVER_URL + this.entity + '?api_token=' + this.auth.getUserToken(), data);
    }

    delete(id) {
        return this.http.delete(SERVER_URL + this.entity + '/' + id + '?api_token=' + this.auth.getUserToken());
    }

    setEntity(entity: string) {
        this.entity = entity;
    }

    uploadFile(formData: FormData) {
        let headers = new HttpHeaders();
        headers.set('Content-Type', null);
        headers.set('Accept', "multipart/form-data");
        return this.http.post(SERVER_URL + 'usersImport' + '?api_token=' + this.auth.getUserToken(), formData, {headers: headers});
    }

    public addFilter(filter: Filter) {
        this.filters.push(filter);
    }

    public removeFilter(filter: Filter) {
        this.filters.splice(this.filters.indexOf(filter), 1);
    }

    public clearFilters() {
        this.filters = [];
        this.queryFilters = '';
    }

    public applyQueryFilters() {
        let queryFilters: string[] = [];
        for (let filter of this.filters) {
            queryFilters.push(filter.toQueryString());
        }
        if (queryFilters.length > 0) {
            this.queryFilters = '&filters=' + queryFilters.join(';');
        }
    }

    public getPaginateInfo(): PaginateInfo {
        return this.paginateInfo;
    }

    private mapArray(data) {
        if (data.total) {
            this.paginateInfo.current_page = data.current_page;
            this.paginateInfo.from = data.from;
            this.paginateInfo.last_page = data.last_page;
            this.paginateInfo.per_page = data.per_page;
            this.paginateInfo.to = data.to;
            this.paginateInfo.total = data.total;
            data = data.data;
        }
        let array = [];
        for (let item of data) {
            array.push(this.map(item));
        }
        
        return array;
    }

    protected abstract map(data);

}